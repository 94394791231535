*,
*:before,
*:after {
  box-sizing: border-box;
  margin-top: 0;
}

body {
  margin: 0;
  font-family: "Georgia W01 Regular", serif;
  line-height: 1.75;
  color: #10111e;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;700&display=swap');

@font-face {
  font-family:"Georgia W01 Bold";
  src:url("https://oak.edu/wp-content/themes/divi-oakedu/build/fonts/904709/886cc2e0-6d9f-4b2e-a486-0148b53b006e.woff2") format("woff2"),url("https://oak.edu/wp-content/themes/divi-oakedu/build/fonts/904709/d2f9060f-3485-482f-b5a0-b91faa67bab3.woff") format("woff");
}

@font-face {
  font-family:"Georgia W01 Regular";
  src:url("https://oak.edu/wp-content/themes/divi-oakedu/build/fonts/904718/3056c93b-ddd6-4a07-b6ac-0b3947b2ee38.woff2") format("woff2"),url("https://oak.edu/wp-content/themes/divi-oakedu/build/fonts/904718/39a18a82-9fba-4a18-9b26-872bc05243b8.woff") format("woff");
}

.logo-container {
  display: flex;
  flex-direction: column;
}

.logo {
  width: 275px;
  margin: 16px auto 48px auto;
}

.bg-container {
  position: absolute;
  width: 100%;
}

.bg-container.stripes {
  padding: 0 40px 0 40px;
}

.bg-container.bg-photo {
  margin-top: -20%;
}

.bg-container img {
  width: 100%;
}

.stripes img {
  height: 246px;
  object-fit: cover;
}

.bg-photo img {
  display: block;
  height: auto;
  object-fit: cover;
  object-position: center;
}

.container {
  display: flex;
  flex-direction: column;
  max-width: 992px;
  margin: 0 auto 0 auto;
  padding: 0 64px;
  position: relative;
}

.title-box {
  background-color: #06357a;
  margin: -32px auto 0 auto;
  max-width: 75%;
  padding: 32px 64px;
  text-align: center;
  width: fit-content;
  z-index: 2;
}

.title-box-arrow {
  content: "";
  background-color: #06357a;
  height: 28px;
  width: 28px;
  margin: -15px auto 16px auto;
  transform: rotate(45deg);
  z-index: 2;
}

h1 {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  font-size: 32px;
  font-family: "Georgia W01 Bold", serif;
  font-weight: bold;
  line-height: 40px;
  margin: 0 auto 32px auto;
  width: fit-content;
}

h1::after {
  content: "";
  background-color: #6caedf;
  height: 6px;
  width: 84%;
  margin: -10px auto 0 auto;
  z-index: -1;
}

h2,
h3,
h4 {
  color: #6caedf;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

h2 {
  font-size: 24px;
  line-height: 30px;
}

h3 {
  font-size: 20px;
  line-height: 22px;
  margin: 0 0 16px 0;
}

h4 {
  color: #072f6f;
  font-weight: bold;
  margin-bottom: 8px;
  text-transform: uppercase;
}

a {
  color: #06357a;
  text-decoration: none;
}

a:hover {
  color: #e1b734;
}

.form-container {
  background-color: #ffffff;
  border: 8px solid #e1b734;
  margin-top: -128px;
  padding: 144px 40px 40px 40px;
  z-index: 1;
}

.form-page {
  display: none;
}

.form-page.active {
  display: block;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

select,
input[type="text"],
input[type="date"] {
  border: 1px solid #707070;
  min-height: 40px;
  padding: 0 16px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: 98%;
  background-size: 16px;
}

select::after {
  content: "";
  width: 0.8em;
  height: 0.5em;
  background-color: #10111e;
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
}

.form-label {
  color: #072f6f;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.checkbox-list {
  column-count: 2;
  column-gap: 20px;
}

.checkbox-control {
  margin-bottom: 8px;
}

.checkbox-control label {
  padding-left: 28px;
  margin-bottom: 8px;
  float: left;
  width: 100%;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: solid 1px #707070;
  height: 16px;
  margin-left: -24px;
  margin-bottom: -3px;
  margin-right: 8px;
  width: 16px;
}

input[type="checkbox"]:checked {
  background-color: #06357a;
}

button {
  align-items: center;
  border: none;
  border-radius: 0%;
  display: flex;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  margin: 16px auto;
  text-transform: uppercase;
  padding: 12px 16px;
}

button.primary {
  background-color: #06357a;
  color: #6caedf;
}

button.primary:hover {
  background-color: #e1b734;
  color: #ffffff;
}

button.primary img {
  height: 24px;
  padding-left: 16px;
}

button.secondary {
  background-color: transparent;
  color: #072f6f;
  text-decoration: underline;
}

button.disabled {
  background-color: #dbe2e4;
  color: #ffffff;
}

.error {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: #fa0033;
}

.indicators {
  display: flex;
  margin: 0;
  padding: 16px 0;
  font-family: "Roboto", sans-serif;
  list-style: none;
  width: 100%;
}

.indicators li {
  color: #072f6f;
  width: 33.3%;
}

.indicators li:nth-child(2) {
  text-align: center;
}

.indicators li:nth-child(3) {
  text-align: right;
}

.indicators li.active {
  font-weight: bold;
}

.scholarships {
  text-align: center;
  margin: 16px 0 32px 0;
}

.scholarships hr {
  background-color: #10111e;
  border: none;
  height: 1px;
  margin: 8px auto;
  width: 30%;
}

.scholarship {
  font-weight: bold;
  margin-bottom: 8px;
}

.disclaimers {
  font-size: 12px;
  line-height: 18px;
}

.followups {
  background-color: #dbe2e4;
  margin-bottom: 32px;
  padding: 32px;
  text-align: center;
}

@media only screen and (max-width: 992px) {
  .container {
    max-width: 768px;
    padding: 0 32px;
  }
}

@media only screen and (max-width: 768px) {
  .title-box {
    max-width: 85%;
    padding: 16px 32px;
  }

  .container {
    max-width: 600px;
    padding: 16px;
  }

  .bg-container.stripes {
    display: none;
  }

  .bg-container.bg-photo {
    position: relative;
    margin-top: 0;
  }

  .checkbox-list {
    column-count: 1;
  }

  .form-container {
    background-color: #ffffff;
    border: 8px solid #e1b734;
    margin-top: -128px;
    padding: 144px 20px 20px 20px;
    z-index: 1;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 0;
  }
}

.mb-32px {
  margin-bottom: 32px;
}
